<template>
  <div class="secretary-form-view">
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
    <app-header icon="users" :title="pageTitle" goes-back></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <form @submit.prevent="onSubmit" autocomplete="off">
            <div class="row">
              <div class="col-3">
                <app-input
                  label="Nome"
                  v-model="secretary.name"
                  :errors="errors.name"
                  is-required
                  type="text"
                >
                </app-input>
              </div>
              <div class="col-3">
                <app-input
                  label="Email"
                  v-model="secretary.user.email"
                  :errors="errors.email"
                  is-required
                  type="email"
                ></app-input>
              </div>
              <div class="col-3">
                <app-input
                  v-if="this.secretaryId"
                  v-model="secretary.password"
                  type="password"
                  required
                  minlength="6"
                  autocomplete="new-password"
                  name="password"
                  label="Senha"
                  :errors="errors.password"
                ></app-input>
                <app-input
                  v-else
                  v-model="secretary.password"
                  type="password"
                  minlength="6"
                  autocomplete="new-password"
                  name="password"
                  label="Senha"
                  :errors="errors.password"
                ></app-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 p-3"><h5>Permissões</h5></div>
                </div>
                <div class="row">
                  <div
                    class="col-3"
                    v-for="permission in permissions"
                    :key="permission.id"
                  >
                    <b-checkbox
                      v-model="userPermissions[permission.id]"
                      class="mr-4"
                      true-value="1"
                      false-value="0"
                      >{{ permission.name }}
                    </b-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 has-text-right">
                <b-button
                  class="button-rounded remove-focus btn-success border-0 px-3"
                  @click="onSubmit"
                  :loading="isSaving"
                  :disabled="isLoading || isSaving"
                >
                  Salvar
                </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CitiesService from '@/services/cities.service';
import PermissionsService from '@/services/permissions.service';

import MASKS from '@/constants/masks.constant';

export default {
  data: () => ({
    MASKS: MASKS,
    secretaryId: null,
    isLoading: false,
    isSaving: false,
    secretary: {
      name: '',
      user: { permissions: [] },
    },
    errors: {},
    permissions: [],
    userPermissions: [],
  }),
  computed: {
    pageTitle() {
      return `${this.secretaryId ? 'Editar' : 'Cadastrar'} Secretária`;
    },
  },
  methods: {
    citiesGetter: CitiesService.search,
    citySetter: CitiesService.getId,
    onSubmit() {
      this.isSaving = true;
      this.errors = {};
      const userPermissions = this.userPermissions;
      let permissions = [];
      userPermissions.forEach(function (e, index) {
        if (e == 1) {
          permissions.push(index);
        }
      });
    },
    loadPermissions(permissions) {
      this.isLoading = true;
      PermissionsService.get()
        .then(({ data }) => {
          let permissionsArray = [];

          if (permissions == null) {
            data.forEach(function (e) {
              permissionsArray[e.id] = e.default;
            });
          } else {
            data.forEach(function (e) {
              permissionsArray[e.id] = 0;
            });

            this.secretary.user.permissions.forEach(function (e) {
              permissionsArray[e.id] = 1;
            });
          }

          this.userPermissions = permissionsArray;
          this.permissions = {
            ...data,
          };
        })
        .catch(() => this.$buefy.snackbar.open('Erro as permissões.'))
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    if (typeof this.$route.params.id == 'undefined') {
      this.loadPermissions(null);
    }
    this.secretaryId = Number(this.$route.params.id);
    this.secretaryId && this.loadSecretary(this.secretaryId);
  },
};
</script>
